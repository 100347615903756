import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "../lib/theme/ThemeProvider";
import AxiosInterceptor from "../api/AxiosInterceptor";
import { AuthView } from "./AuthView";
import { App } from "./App";
import { RequireAuth } from "../components/RequireAuth";
import Auth0ProviderWithRedirectCallback from "../components/Auth0WithRedirect";
import { LoginView } from "./LoginView";
import { useSettingsState } from "../components/useSettingsStore";
import { toMuiLanguageCode } from "src/lib/theme/toMuiLanguageCode";

const domain =
  typeof process.env.REACT_APP_AUTH0_DOMAIN !== "undefined"
    ? process.env.REACT_APP_AUTH0_DOMAIN
    : "";
const clientId =
  typeof process.env.REACT_APP_AUTH0_CLIENT_ID !== "undefined"
    ? process.env.REACT_APP_AUTH0_CLIENT_ID
    : "";
const audience =
  typeof process.env.REACT_APP_AUTH0_AUDIENCE !== "undefined"
    ? process.env.REACT_APP_AUTH0_AUDIENCE
    : "";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // don't retry on dev (default is 3)
      retry: process.env.NODE_ENV !== "development" ? 3 : false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0ProviderWithRedirectCallback
        domain={domain}
        clientId={clientId}
        useRefreshTokens
        useRefreshTokensFallback
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <AxiosInterceptor />
          <Outlet />
        </QueryClientProvider>
      </Auth0ProviderWithRedirectCallback>
    ),
    children: [
      { path: "/", element: <LoginView /> },
      {
        path: "*",
        element: (
          <RequireAuth>
            <App />
          </RequireAuth>
        ),
      },
    ],
  },
]);

export const Root = () => {
  const { theme, language } = useSettingsState();
  return (
    <ThemeProvider mode={theme ?? "dark"} locale={toMuiLanguageCode(language)}>
      <CssBaseline />
      <RouterProvider
        router={router}
        fallbackElement={<AuthView>Loading...</AuthView>}
      />
    </ThemeProvider>
  );
};
